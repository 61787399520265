
@font-face {
  font-family: 'SF Pro Bold';
  src: url('../Static/SF-Pro-Display-Bold.otf') format('opentype'),
       url('../Static/SF-Pro-Display-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;

}


@font-face {
  font-family: 'SF Pro Semibold';
  src: url('../Static/SF-Pro-Text-Semibold.otf') format('opentype'),
       url('../Static/SF-Pro-Text-Semibold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}


* {
  font-family: 'Roboto Mono', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  overflow-x: hidden;
}

body {
  background-image: url('../Static/bruhgif.gif');
  margin: 0px;
  padding: 0px;
  background-color: #FEFFED;
  overflow-x: hidden;
}

.square-cover {
  z-index: -5;
  opacity: 85%;
  width: 100%;
  height:330%;
  color:black;
  background-color: black;
  position: absolute;
}

::-webkit-scrollbar {
  display: none;
}

::selection {
  background-color: #0500FF;
  color: #ffffff;
}

button:focus {
  box-shadow: none !important;
}

button:hover {
  transform: translate(0, -3px);
  box-shadow: 0px 9px 11px rgba(0, 0, 0, 0.04);
}

button:active {
  transform: translate(0, 0px);
  box-shadow: 0px 9px 11px rgba(0, 0, 0, 0.04);
}

button {
  cursor: pointer;
  user-select: none;
  transition: .15s;
}

#light {
  color: #ffffff;
}

#changeColor {
  color: #000;
}

#changeSize {
  position: relative;
  left: 50%;
  transform: translate(-50%);
}

.test-spam {
  display: inline;
  font-family: 'Times New Roman', Times, serif;
  font-size: 20px;
  color: #0500FF;
  cursor: pointer;
}

.alwaysshowme:hover .showmeonhover {
  display: inline;
  animation: 350ms fadeIn ease;
}

.showmeonhover {
  display: none;
  position: absolute;
  box-shadow: 0px 4px 53px rgba(0, 0, 0, 0.40);
  border-radius: 6px;
  margin-top: -165px;
  margin-left: -165px;
}

.alwaysshowme:hover .showmeonhover2 {
  display: inline;
  animation: 350ms fadeIn ease;
}

.showmeonhover2 {
  display: none;
  position: absolute;
  box-shadow: 0px 4px 53px rgba(0, 0, 0, 0.40);
  border-radius: 6px;
  margin-top: -205px;
  margin-left: -205px;
}


#burger-test {
  color: #000;
}


#padding-top {
  display: flex;
  justify-content: center;
}

#small-font {
  font-size: 16px;
  color: #A4A4A4;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

#small-font-2 {
  font-size: 16px;
  color: #A4A4A4;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 100px;
  margin-top: 15px;
}

.big-font {
  font-family: 'SF Pro Bold';
  font-size: 64px;
  color: white;
  margin-bottom: 15px;
  font-weight: 900;
}

.small-text {
  font-family: 'SF Pro Semibold';
  margin-top: 0;
  font-size: 18px;
  font-weight: 700;
  color: white;
}

#red {
  color: #FF4B4B;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 50px;
}




.center {
  display: flex;
  justify-content: center;
  align-items: center;
}


.header {
  overflow: hidden;
  padding: 45px 200px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  margin-bottom: 40px;
  z-index: 100;
}



.ballz-btn {
  float: left;
  text-align: center;
  padding: 8px 16px;
  text-decoration: none;
  background-color: white;
  color: black;
  font-size: 17px;
  border-radius: 6px;
  border: none;
  margin-right: 8px;
  margin-top: 8px;
  font-weight: 1000;
  opacity: 85%;
}

.ca-btn {
  float: center;
  text-align: center;
  padding: 8px 16px;
  text-decoration: none;
  background-color: white;
  color: black;
  font-size: 17px;
  border-radius: 6px;
  border: none;
  margin-right: 8px;
  margin-top: 8px;
  font-weight: 900;
  opacity: 85%;
}

.footer-btn {
    float: left;
    text-align: center;
    padding: 8px 16px;
    text-decoration: none;
    background-color: white;
    color: black;
    font-size: 17px;
    border-radius: 6px;
    border: none;
    margin-left: 200px;
    margin-right: 8px;
    margin-bottom:20px;
    font-weight: 900;
    opacity: 85%;  
}

.ballz-btn:hover {
  opacity: 100%;
}

.btn-light {
  float: left;
  text-align: center;
  padding: 8px 16px;
  text-decoration: none;
  background-color: #E9E9E9 !important;
  color: #000;
  font-size: 17px;
  border-radius: 6px;
  border: none;
  margin: 8px;
  font-weight: 700;
  opacity: 85%;
}

.btn-light span {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-light:hover {
  background-color: #E9E9E9;
  opacity: 100%;
}

.btn-light:focus {
  border: none;
  box-shadow: none;
  outline: none;
}

.btn-light-2 {
  float: left;
  text-align: center;
  padding: 8px 16px;
  text-decoration: none;
  background-color: #000 !important;
  color: #ffffff;
  font-size: 17px;
  border-radius: 6px;
  border: none;
  margin: 8px;
  font-weight: 700;
  opacity: 85%;
}

.btn-light-2 span {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-light-2:hover {
  background-color: #E9E9E9;
  opacity: 100%;
}

.btn-light-2:focus {
  border: none;
  box-shadow: none;
  outline: none;
}

.btn-light-3 {
  float: left;
  text-align: center;
  padding: 8px 16px;
  text-decoration: none;
  background-color: #121212 !important;
  color: #ffffff;
  font-size: 17px;
  border-radius: 6px;
  border: none;
  margin: 8px;
  font-weight: 700;
  opacity: 85%;
}

.btn-light-3 span {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-light-3:hover {
  background-color: #E9E9E9;
  opacity: 100%;
}

.btn-light-3:focus {
  border: none;
  box-shadow: none;
  outline: none;
}

#padding {
  padding-left: 10px;
}

#dark-ballz {
  background-color: #121212;
  color: #ffffff;
}

#light-ballz {
  background-color: #ffffff;
  color: #000;
}


.header-right {
  float: right;
}

.footer-right {
  float: right;
  margin-right: 200px;
  margin-bottom: 20px;
}

.burger-mobile {
  display: none;
}

.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0px;
  margin: 0px;
  z-index: -1;
}

.xiaoImg1 {
  margin-right: 75px;
  margin-left: 75px;
  display: inline-block;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-weight: 800;
}

.xiaoImg2 {
  margin-right: 75px;
  margin-left: 75px;
  display: inline-block;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-weight: 800;
}
.xiaoImg3 {
  margin-right: 75px;
  margin-left: 75px;
  display: inline-block;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-weight: 800;
}

.container3 {
    display: flexbox;
    text-align: center;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0px;
    margin: 0px;
    z-index: -1;
}

.xiaoImages {
  display: inline-block;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

@media (max-width: 798px) {
    .container3 {
      top: 30%;
      width: 80%;
    }

    .big-font {
      font-size: 40px;
    }

    .ca-btn {
      display: none;
    }



    .footer {
      display: none !important;
    }

    .footer-btn {
      display: none !important;
    }
    .xiaoImages {
      top: 65%;
      display: flex;
      flex-wrap: nowrap; 
      overflow-x: auto; 
      justify-content: center; 
    }
    
    .xiaoImg1 {
      margin: 0 5px; 
      flex: 0 1 auto;
      min-width: 30%; 
    }
    .xiaoImg2 {
      margin: 0 5px; 
      flex: 0 1 auto; 
      min-width: 30%; 
    }
    .xiaoImg3 {
      margin: 0 5px;
      flex: 0 1 auto;
      min-width: 30%; 
      
    }
    .xiaoImg1 img {
      max-width: 100%; 
      height: auto;
    }
    .xiaoImg2 img {
      max-width: 100%; 
      height: auto; 
    }
    .xiaoImg3 img {
      max-width: 100%; 
      height: auto; 
    }

    .container4 img {
      max-width: 100%;
      height: auto;
    }
    .container4 {
      top: 140% !important;
    }

    .container5 {
      top: 265% !important;
    }

    .footer-right {
      display:none !important;
    }




      .mySwiper {
        width: 100% !important;

      }
    
      .swiper-slide img { 
        display: block !important;
        width: 400px !important; 
        height: auto !important;
        margin-bottom: 50px !important;  
      }

      .swiper-slide p {
        text-align: center;
        margin-top: 80px;
        margin-bottom: 50px;
      }

      .mySwiper .swiper-pagination {
        margin-top: 100px !important; 
      }
      
      
        
  }

.container4 {
  display: flexbox;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 150%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0px;
  margin: 0px;
  z-index: -1;
  width: 100%;
}

.container5 {
  display: flexbox;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 260%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0px;
  margin: 0px;
  z-index: -1;
  width: 100%;

}


.swiper {
  width: 40%;
}
.swiper-slide {
  display: flex;
  flex-direction: column;
  align-items: center; 
}


.swiper-slide img {
  display: block;
  width: 70%; 
  height: auto;
  margin-bottom: 10px; 
}

.swiper-slide p {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 50px;
}

.mySwiper .swiper-pagination {
  margin-top: 50px; /* Adjust the value as needed */
}


.footer {
  background-color: black;
  height: 7%;
  display: flex;
  width: 100%;
  position: absolute;
  top: 330%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  margin: 0px;
  z-index: -1;
  justify-content: center;
  text-align: center;
  align-items: center;
  overflow: hidden;
  padding-bottom: 0px;
  margin-right: 10%;
  z-index: 100;
}


.caption {
  font-family: 'SF Pro Bold';
  font-size: 30px;
  margin-bottom: 80px;
  font-weight: 1000;
  color: white;
}

.pump-button {
    color: white;
    font-size: 18px;
    width:calc(150px);
    height:calc(65px);
    background: rgb(0, 60, 255);
    border-radius:16px;
    display:flex;
    text-align: center;
    align-items:center;
    justify-content:center;
    text-transform:uppercase;
    font-weight:bold;
    text-decoration: none;
    user-select: none;
    transition: .15s;
    
  
  }


.pump-button:hover {
    user-select: none;
    transform: translate(0, -2px);
    box-shadow: 0px 9px 11px rgba(0, 0, 0, 0.04);  
    cursor: pointer;
    
  }

  pump-button:active {
    transform: translate(0, -2px);
    box-shadow: 0px 9px 11px rgba(0, 0, 0, 0.04);
  }



.container2 {
    display:flex;
    width: 100%;
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0px;
    margin: 0px;
    z-index: -1;
    justify-content: center;
    text-align: center;
    align-items: center;
  }





.burger-mobile-2 {
  display: none;
}


a {
  text-decoration: none;
}

.swiper-pagination-bullet {
  COLOR: white;
}

.mobile-button {
  border: none;
  background-color: transparent;
  font-size: 18px;
  margin-bottom: 40px;
  margin-top: 50px;
  margin-left: 30px;
  color: rgb(214, 214, 214);
  font-weight: 500;
  text-decoration: none;
}

.mobile-button-2 {
  border: none;
  background-color: transparent;
  font-size: 18px;
  margin-bottom: 40px;
  margin-top: 50px;
  margin-left: 30px;
  color: rgb(82, 82, 82);
  font-weight: 500;
  text-decoration: none;
}

.connect-mobile {
  width: 90%;
  height: 58px;
  background: #000000;
  border-radius: 6px;
  border: none;
  color: #E9E9E9;
  font-size: 18px;
  overflow: hidden;
  font-weight: 900;
  margin-bottom: 30px;
  box-shadow: 0px 4px 67px rgba(247, 228, 228, 0.09);
  font-family: 'Roboto', sans-serif;
}

hr {
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
  border: solid #4d4d4d;
  border-width: 0px 0px 1px;
}

#button-1 {
  margin-left: 25px;
}

.close {
  background-color: transparent;
  border: none;
  float: right;
  padding-top: 12px;
  cursor: pointer;
}

.icons-mobile {
  display: flex;
  align-items: center;
}

.socail-button {
  background-color: transparent;
  border: none;
  margin-left: 20px;
  margin-top: 40px;
}

.modal-2,
.overlay-2 {
  width: 100vw;
  height: 100vh;
  top: 0;
  position: fixed;
  z-index: 1;
  margin-top: 100px;
}

.modal-content {
  border: none;
  border-radius: 0px;
  z-index: 1;
}

.modal-content-wrapper {
  position: fixed;
  width: 100%;
  height: 300px;
  background: black;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-top: 110px;
  z-index: 1;
}

.header-mobile {
  background: black;
  width: 100vw;
  height: 120px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 2;
  border-bottom: solid #252525;
  border-width: 0px 0px 1px;
}

.overlay-2 {
  background: rgba(34, 34, 34, 0.90);
  z-index: 0;
  animation: 350ms fadeIn;
}

.button-main-mobile {
  width: 92px;
  height: 37px;
  background: #000000;
  border-radius: 6px;
  border: none;
  color: #E9E9E9;
  font-size: 18px;
  z-index: 100;
  font-weight: 900;
  font-family: 'Roboto', sans-serif;
  margin-left: 3px;
}

.line-top {
  width: 100%;
}

.ballz-btn-mobile {
  float: left;
  text-align: center;
  padding: 8px 16px;
  text-decoration: none;
  background-color: white;
  color: black;
  font-size: 17px;
  border-radius: 6px;
  border: none;
  margin-left: 40px;
  margin-top: 38px;
  font-weight: 900;
}

.slide {
  width: 300px;
}

.center {
  display: flex;
  justify-content: center;
}

.menu {
  background: #E9E9E9;
  border-radius: 6px;
  position: absolute;
  width: 135px;
  margin-left: 378px;
  margin-top: 55px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease, transform 0.2s ease, visibility 0.2s;
  z-index: 100;
}

.menu3 {
  border-radius: 6px;
  width: 145px;
  opacity: 0;
  display: none;
  z-index: 100;
}

.menu3.active {
  opacity: 1;
  display: block;
  transform: translateY(0);
}

.menu3 ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-left: 40px;
  margin-top: 5px;
}

.menu3 li a {
  text-decoration: none;
  color: #000;
  padding: 10px 10px;
  display: block;
}

.menu3 li a:hover {
  opacity: 75%;
  transition: .2s;
  cursor: pointer;
}

.menu.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu li a {
  text-decoration: none;
  color: #000;
  padding: 10px 16px;
  display: block;
  font-weight: 700;
}

.menu li a:hover {
  opacity: 75%;
  transition: .2s;
  cursor: pointer;
}

.menu-trigger {
  width: 125px;
  height: 37px;
  background: #E9E9E9;
  border-radius: 6px;
  border: none;
  color: #000;
  font-size: 16px;
  margin-left: 12px;
  transition: box-shadow 0.4s ease;
}

.select-test {
  width: 80px;
  margin-top: 30px;
  margin-right: 20px;
  outline: none;
}

.video-mani {
  width: 80%;
  height: 80%;
  position: absolute;
  top: 56.50%;
  left: 50%;
  border-radius: 30px;
  transform: translate(-50%, -50%);
  background-color: #121212;
}

.manifesto-title-video {
  display: none;
}

.back-to-top {
  display: none;
}

.color-change {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100%;
  padding: 0px;
  margin: 0px;
  animation: 16s infinite bgcolorchange;
}

.black-background {
  height: 100%;
  background-color: black;
  border-radius: 12px;
  margin-left: 80px;
  margin-right: 80px;
  margin-top: 60px;
}

.black-background-2 {
  width: 100vw;
  height: 100%;
  background-color: black;
  padding-bottom: 60px;
}

#white-background {
  color: #ffffff;
  padding-left: 50px;
  padding-right: 50px;
}

#white-background-2 {
  color: #000;
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 75px;
  background-color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 95%;
  margin-left: 2.5%;
  position: relative;
  top: -20px;
  border-radius: 12px;
  font-size: 18px;
  box-shadow: 0 0 50px 2px rgba(1, 1, 1, 0.20);
}

#white-background-3 {
  color: white;
  padding-left: 50px;
  padding-right: 50px;
  background-color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 95%;
  margin-left: 2.5%;
  border-radius: 12px;
  margin-bottom: 60px;
  margin-top: 20px;
  font-size: 18px;
  box-shadow: 0 0 50px 2px rgba(1, 1, 1, 0.20);
}

.discord-message-wrapper {
  width: calc(100vw - 20px);
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 40px;
  width: 100%;
  transition: .5s;
}

.discord-message-wrapper-1 {
  height: 11rem;
  margin-bottom: 40px;
}

.discord-message-wrapper-2 {
  height: 6rem;
}

.discord-message {
  margin: 1rem;
}

.test-zoom-1 {
  height: 10rem;
  width: auto;
  border-radius: 6px;
}

.test-zoom-2 {
  height: 5rem;
  width: auto;
  border-radius: 6px;
}

.discord-message:hover {
  transform: translate(0, -3px);
  box-shadow: 0px 9px 11px rgba(0, 0, 0, 0.04);
  transition: .15s;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-250px * 37));
  }
}

@keyframes scroll2 {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-250px * 14));
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0%;
  }

  100% {
    opacity: 100%;
  }
}

@media screen and (min-width: 1300px) {

  .modal-2,
  .overlay-2 {
    display: none;
  }

  .overlay-2 {
    display: none;
  }

  .modal-content-2 {
    display: none;
  }

  .header-mobile {
    display: none;
  }

  .modal-content {
    display: none;
  }

  .modal-content-wrapper {
    display: none;
  }
}

@media screen and (max-width: 1400px) {
  .main-text {
    font-size: 350px;
  }
}

@media screen and (max-width: 1315px) {
  .btn-light {
    display: none;
  }

  .btn-light-2 {
    display: none;
  }

  .btn-light-3 {
    display: none;
  }

  #dark-ballz {
    background-color: #ffffff;
    color: #000;
  }

  .connect-btn {
    display: none;
  }

  .main-text {
    font-size: 180px;
  }

  .main-text span {
    font-size: 120px;
  }

  .burger-mobile {
    display: block;
    background-color: transparent;
    border: none;
    margin-top: -3px;
    cursor: pointer;
    position: relative;
    z-index: 10;
    color: white;
  }

  .burger-mobile-2 {
    display: block;
    background-color: transparent;
    border: none;
    margin-top: 5px;
    cursor: pointer;
    position: relative;
    z-index: 10;
    color: #ffffff;
  }




  .burger-mobile {
    display: block;
    background-color: transparent;
    border: none;
    padding-top: 10px;
  }


  .header {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 30px;
  } }